import React from 'react';

type FormattedDateTimeProps = {
  timestamp: string | number | Date;
  locales?: string | string[];
} & Pick<Intl.DateTimeFormatOptions, 'dateStyle' | 'timeStyle'>;

function FormattedDateTime({
  timestamp,
  dateStyle,
  timeStyle,
  locales,
}: FormattedDateTimeProps): JSX.Element {
  const formatted = new Intl.DateTimeFormat(locales ?? [], {
    dateStyle,
    timeStyle,
  }).format(timestamp ? new Date(timestamp) : new Date());

  return <>{formatted}</>;
}

export default FormattedDateTime;
