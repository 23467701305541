import React from 'react';
import PropTypes from 'prop-types';

function ArticleTitle({ title, backgroundImageUrl, isSlim = false }) {
  return (
    <div className={`article-title ${isSlim ? 'article-title--slim' : ''}`}>
      <div className={'article-title__image-container'}>
        <img
          className={'article-title__image'}
          src={backgroundImageUrl}
          alt={'Decorative background for title'}
        />
      </div>
      <div
        className={`article-title__fade ${
          isSlim ? 'article-title__fade--slim' : ''
        }`}
      />
      {title && <h1 className={'article-title__text'}>{title}</h1>}
    </div>
  );
}
ArticleTitle.propTypes = {
  title: PropTypes.string,
  backgroundImageUrl: PropTypes.string.isRequired,
  isSlim: PropTypes.bool,
};

export default ArticleTitle;
