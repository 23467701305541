import React, { useRef } from 'react';
import Link from 'next/link';
import styles from './Navigation.module.scss';
import CmsNavigationViewModel from '../../../resources/js/typings/viewModels/cmsNavigationViewModel';

function Navigation({ options }: CmsNavigationViewModel): JSX.Element {
  const ref = useRef<HTMLElement | null>(null);

  return (
    <nav ref={ref} className={styles.navigation}>
      {options.map((option, index) => (
        <Link
          key={index}
          href={option.href}
          aria-label={option.label}
          className={`${styles.option} ${
            option.isSelected ? styles.selectedOption : ''
          }`}
        >
          <span className={styles.shortenedLabel}>{option.shortenedLabel}</span>
          <span className={styles.label}>{option.label}</span>
          {option.isSelected ? (
            <div className={styles.selectionHighlight} />
          ) : null}
        </Link>
      ))}
    </nav>
  );
}

export default Navigation;
