import React, { createRef, ReactNode } from 'react';
import ArticlePreviewViewModel from '../../typings/viewModels/articlePreviewViewModel';
import useGame from '../../contexts/useGame';
import renderEnhancedMarkdownToString from '../EnhancedMarkdown/helpers/renderEnhancedMarkdownToString';
import EnhancedMarkdown from '../EnhancedMarkdown/EnhancedMarkdown';
import MaybeLink from '../MaybeLink/MaybeLink';
import FormattedDateTime from '../FormattedDateTime/FormattedDateTime';

export type LinkProps = {
  children?: ReactNode;
  className?: string;
};
export type LinkElement = (props: LinkProps) => JSX.Element | null;
export type ImageElement = (props: {
  children?: ReactNode;
}) => JSX.Element | null;

export type ArticlePreviewProps = ArticlePreviewViewModel & {
  style?: 'default' | 'small';
  className?: string;
  isProminent?: boolean;
  locales?: string | string[];
  ArticleLink?: LinkElement;
  CategoryLink?: LinkElement;
  Image?: ImageElement;
};

function ArticlePreview({
  style = 'default',
  categoryName,
  url,
  categoryUrl,
  imageUrl,
  title,
  description,
  timestamp,
  status,
  className = '',
  isProminent = false,
  locales,
  ArticleLink,
  CategoryLink,
  Image,
}: ArticlePreviewProps): JSX.Element | null {
  const game = useGame();
  const ref = createRef<HTMLDivElement>();

  if (!game) return null;

  // NOTE: Next.js requires this to be called after other useHooks
  const renderedTitle = renderEnhancedMarkdownToString(title, game);

  ArticleLink =
    ArticleLink ??
    React.memo(function ArticlePreviewLink({
      children,
      className = '',
    }: LinkProps) {
      return (
        <MaybeLink href={url} className={className}>
          {children}
        </MaybeLink>
      );
    });

  CategoryLink =
    CategoryLink ??
    React.memo(function CategoryLink({ children, className = '' }: LinkProps) {
      return (
        <MaybeLink href={categoryUrl} className={className}>
          {children}
        </MaybeLink>
      );
    });

  Image =
    Image ??
    React.memo(function ArticlePreviewImage() {
      return <img src={imageUrl} alt={renderedTitle} />;
    });

  return (
    <div className={className}>
      <ArticleLink>
        <div
          ref={ref}
          className={
            `article-preview article-preview--${style}` +
            (isProminent ? ' article-preview--prominent' : '')
          }
        >
          <div
            className={`article-preview__image article-preview__image--${style}`}
          >
            <Image />
            {status && (
              <div className={'article-preview__status'}>{status}</div>
            )}
          </div>
          <div className={'article-preview__details'}>
            <div className={'article-preview__pre-title'}>
              <span className={'article-preview__category-name'}>
                <CategoryLink className={'article-preview__link'}>
                  {categoryName}
                </CategoryLink>
              </span>
              <span> - </span>
              <FormattedDateTime
                timestamp={timestamp}
                dateStyle={'long'}
                locales={locales}
              />
            </div>
            <div className={'article-preview__title'}>{renderedTitle}</div>
            {description && (
              <div className={'article-preview__description'}>
                <EnhancedMarkdown markdown={description} wrapped={false} />
              </div>
            )}
          </div>
        </div>
      </ArticleLink>
    </div>
  );
}

export default ArticlePreview;
