import React from 'react';
import {
  default as DefaultArticlePreview,
  ArticlePreviewProps,
} from '../ArticlePreview/ArticlePreview';

type ArticlePreviewListProps = {
  articlePreviews: ArticlePreviewProps[];
  isHorizontal?: boolean;
  zebra?: 'even' | 'odd' | 'none';
  ArticlePreview?: typeof DefaultArticlePreview;
};

const ArticlePreviewList = ({
  articlePreviews,
  isHorizontal = false,
  zebra = 'even',
  ArticlePreview = DefaultArticlePreview,
}: ArticlePreviewListProps): JSX.Element => {
  return (
    <div
      className={[
        'article-preview-list',
        isHorizontal
          ? 'article-preview-list--horizontal'
          : 'article-preview-list--vertical',
      ].join(' ')}
    >
      {articlePreviews.map((articlePreview) => (
        <ArticlePreview
          {...articlePreview}
          key={articlePreview.id}
          style={isHorizontal ? 'small' : 'default'}
          className={`article-preview-list__article-preview article-preview-list__article-preview--zebra-${zebra}`}
        />
      ))}
    </div>
  );
};

export default ArticlePreviewList;
