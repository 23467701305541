import {
  default as RealArticlePreview,
  ArticlePreviewProps,
  LinkProps,
} from '../../../resources/js/components/ArticlePreview/ArticlePreview';
import React from 'react';
import Link from 'next/link';
import useGame from '../../../resources/js/contexts/useGame';
import renderEnhancedMarkdownToString from '../../../resources/js/components/EnhancedMarkdown/helpers/renderEnhancedMarkdownToString';
import OptimizedImage from '../OptimizedImage/OptimizedImage';

const ArticlePreview = (
  articlePreview: ArticlePreviewProps
): JSX.Element | null => {
  const game = useGame();

  if (!game) return null;

  const ArticleLink = ({ className, children }: LinkProps) => {
    return (
      <Link href={articlePreview.url} className={className}>
        {children}
      </Link>
    );
  };

  const CategoryLink = ({ className, children }: LinkProps) => {
    return (
      <Link href={articlePreview.categoryUrl} className={className}>
        {children}
      </Link>
    );
  };

  const Image = () => {
    const renderedTitle = renderEnhancedMarkdownToString(
      articlePreview.title,
      game
    );

    return (
      <OptimizedImage
        src={articlePreview.imageUrl}
        alt={renderedTitle}
        isZoomable={false}
        includeMargin={false}
        priority={articlePreview.isProminent}
      />
    );
  };

  return (
    <RealArticlePreview
      {...articlePreview}
      locales={'en-US'}
      ArticleLink={ArticleLink}
      CategoryLink={CategoryLink}
      Image={Image}
    />
  );
};

export default ArticlePreview;
