import React from 'react';
import PropTypes from 'prop-types';

function Content({ width = 'full', children, className }) {
  return (
    <div className={`content content--${width}-width ${className}`}>
      {children}
    </div>
  );
}
Content.propTypes = {
  width: PropTypes.oneOf(['full', 'medium', 'narrow']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Content;
