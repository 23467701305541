import React from 'react';
import { GameViewModel } from '../../../resources/js/typings/viewModels/gameViewModel';
import HeaderTitleViewModel from '../../../resources/js/typings/viewModels/headerTitleViewModel';
import { ViewModels, ViewModelsProvider } from '../../contexts/useViewModels';
import { GameContextProvider } from '../../../resources/js/contexts/useGame';
import ArticleIndexPageViewModel from '../../../resources/js/typings/viewModels/articleIndexPageViewModel';
import ArticlePreview from '../ArticlePreview/ArticlePreview';
import VerticalContent from '../../../resources/js/components/VerticalContent/VerticalContent';
import Navigation from '../Navigation/Navigation';
import CmsNavigationViewModel from '../../../resources/js/typings/viewModels/cmsNavigationViewModel';
import Content from '../../../resources/js/components/Content/Content';
import styles from './ArticleIndexPage.module.scss';
import Link from 'next/link';
import ArticlePreviewList from '../../../resources/js/components/ArticlePreviewList/ArticlePreviewList';
import ArticleTitle from '../../../resources/js/components/ArticleTitle/ArticleTitle';
import convertImageUrlToOptimizedImageUrl from '../../helpers/convertImageUrlToOptimizedImageUrl';
import Head from 'next/head';
import LinkButton from '../../../resources/js/components/LinkButton/LinkButton';
import {
  ArchonHeaderViewModel,
  getGameLabel,
} from '../ArchonHeader/ArchonHeader';
import { ArchonFooterViewModel } from '../ArchonFooter/ArchonFooter';
import ArchonLayout from '../../layouts/ArchonLayout/ArchonLayout';

export type ArticleIndexPageProps = {
  game: GameViewModel;
  header: ArchonHeaderViewModel;
  footer: ArchonFooterViewModel;
  headerTitle: HeaderTitleViewModel;
  cmsNavigation: CmsNavigationViewModel;
  articleIndexPage: ArticleIndexPageViewModel;
  viewModels: ViewModels;
};

export default function ArticleIndexPage({
  game,
  viewModels,
  header,
  footer,
  headerTitle,
  cmsNavigation,
  articleIndexPage: {
    breadcrumbs,
    prominentArticle,
    featuredArticles,
    recentArticles,
    preventIndexing,
  },
}: ArticleIndexPageProps): JSX.Element {
  const title = 'Articles';
  const description = `The latest news and help articles from ${game?.siteTitle}.`;
  const featuredImageUrl = prominentArticle.imageUrl;

  return (
    <ViewModelsProvider value={viewModels}>
      <GameContextProvider game={game}>
        <ArchonLayout header={header} footer={footer} breadcrumbs={breadcrumbs}>
          <Head>
            <title>{`${title} | Archon (${getGameLabel(header)})`}</title>
            <meta property='og:title' key='og:title' content={title} />
            <meta name='description' key='description' content={description} />
            <meta
              property='og:description'
              key='og:description'
              content={description}
            />
            {preventIndexing && (
              <>
                <meta name='robots' content='noindex' />
                <meta name='googlebot' content='noindex' />
              </>
            )}
            <meta property='og:type' key='og:type' content='website' />
            {featuredImageUrl && (
              <>
                <meta
                  key='og:image'
                  property='og:image'
                  content={featuredImageUrl}
                />
                <meta property='twitter:image' content={featuredImageUrl} />
                <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:title' content={title} />
              </>
            )}
          </Head>
          <VerticalContent>
            <ArticleTitle
              title={'Featured Articles'}
              backgroundImageUrl={convertImageUrlToOptimizedImageUrl(
                headerTitle.backgroundImageUrl,
                384,
                50
              )}
              isSlim
            />
            <Content width={'medium'}>
              <Navigation {...cmsNavigation} />
              <VerticalContent className={styles.topContent}>
                <ArticlePreview {...prominentArticle} isProminent={true} />
                <ArticlePreviewList
                  ArticlePreview={ArticlePreview}
                  articlePreviews={featuredArticles}
                  isHorizontal={true}
                  zebra={'odd'}
                />
              </VerticalContent>
              <div className={styles.recentArticlesHeader}>Recent Articles</div>
              <div className={styles.bottomContent}>
                <ArticlePreviewList
                  ArticlePreview={ArticlePreview}
                  articlePreviews={recentArticles}
                  zebra={'even'}
                />
              </div>
              <div className={styles.categoryLinks}>
                {cmsNavigation.options.slice(1).map((option) => (
                  <LinkButton
                    href={option.href}
                    key={option.label}
                    Wrapper={({ children }) => (
                      <Link href={option.href}>{children}</Link>
                    )}
                  >
                    {`See More ${option.label}`}
                  </LinkButton>
                ))}
              </div>
            </Content>
          </VerticalContent>
        </ArchonLayout>
      </GameContextProvider>
    </ViewModelsProvider>
  );
}
